import React from 'react';
import {
    ListValidationError,
    RequestValidationError,
    ParentValidationErrorInterface,
    ValidationError,
    ValidationErrorInterface,
} from 'tg-resources';
import { gettext } from 'core/utils/text';

export function isParentError(
    error: any
): error is ParentValidationErrorInterface {
    return (
        error instanceof ValidationError ||
        error instanceof ListValidationError ||
        error instanceof ValidationError
    );
}

const ErrorState: React.FC<{
    code: number;
    message?: string;
    errors?: RequestValidationError;
}> = ({ code, message, errors }) => {
    if (message) {
        return <div>{message}</div>;
    }
    if (errors?.hasError && errors.hasError()) {
        return (
            <div>
                {errors.errors.map((error: ValidationErrorInterface) => (
                    <h4 className="text-danger" key={error.fieldName}>
                        {error.errors.map((err) => (
                            <span key={err}>{err}</span>
                        ))}
                    </h4>
                ))}
                {(
                    (isParentError(errors?.errors) &&
                        errors.errors.getError('', true)) ??
                    []
                ).map((error: string) => (
                    <h4 className="text-danger" key={error}>
                        <span>{error}</span>
                    </h4>
                ))}
            </div>
        );
    }

    if (code === 403) {
        return (
            <div>
                {gettext(
                    "You don't have permission to do that. Please check you haven't logged out in another tab."
                )}
            </div>
        );
    }
    return (
        <div>
            {gettext(
                'There might be a slight problem somewhere deep in the server... :/'
            )}
        </div>
    );
};

export default ErrorState;
